<template>
  <div class="admin">
    <div class="sub-wrap">
      <div class="container">
        <header class="admin-header">
          <h1>{{ $t("global.campaigns") }}</h1>
        </header>
        <admin-nav></admin-nav>
        <div class="admin-body">
          <form @submit="update_campaign" class="form" method="post">
            <p>
              <strong>Infos:</strong><br />
              Budget: {{ campaign.budget_formatted }} <span v-if="campaign.start_date">// Start: {{ campaign.start_date }}</span
              ><span v-if="campaign.end_date"> // Ende: {{ campaign.end_date }}</span>
            </p>
            <p>
              <strong>User:</strong><br />
              Account: <router-link :to="'/admin/users/' + campaign.user.uuid" class="campaign-wrap">
                      <span class="material-icons md-10" style="color: #444"> face</span>
              </router-link> // E-Mail:{{ campaign.user.email }} // Vorname: {{ campaign.user.first_name }} // Name: {{ campaign.user.last_name }}
            </p>
            <p>
            <strong>Statistiken:</strong><br />
              Impressionen: {{ campaign.impressions }} // QRCode-Scans: {{ campaign.qr_scan_count }} // Kontakte:{{ campaign.contacts }}
            </p>
            <hr style="margin: 25px 0" />

            <div class="form-wrap">
              <label for="name">{{ $t("global.name") }}</label>
              <input v-model="campaign.name" class="form-input" type="text" name="name" id="name" />
            </div>

            <div class="form-wrap select-wrap">
              <label for="street_number">Status</label>
              <select v-model="campaign.status" class="select-wrap">
                <option value="draft">Entwurf</option>
                <option value="creating">In Erstellung</option>
                <option value="created">Erstellt</option>
                <option value="pending">Warte auf Freischaltung</option>
                <option value="running">Aktiv</option>
                <option value="stopped">Gestoppt</option>
                <option value="paused">Pausiert</option>
                <option value="blocked">Geblockt</option>
                <option value="deleted">Gelöscht</option>
              </select>
            </div>

            <div class="form-wrap select-wrap">
              <label for="industry">Branche</label>
              <select v-model="industry" v-on:change="onChange" class="select-wrap">
                <option value="">Bitte auswählen</option>
                <option v-for="ind in industries" v-bind:value="ind.id" v-bind:key="ind.id">
                  {{ ind.name }}
                </option>
              </select>
            </div>

            <div class="form-wrap select-wrap" v-if="industry">
              <label for="subIndustry">Unterbranche auswählen</label>
              <select v-model="subIndustry" class="select-wrap">
                <option v-for="sub in subIndustries" v-bind:value="sub.id" v-bind:key="sub.id">
                  {{ sub.name }}
                </option>
              </select>
            </div>

            <div v-if="campaign.status == 'running' || campaign.status == 'paused'" class="content-wrap">
              <header class="content-header cf">
                <h3>{{ $t("campaigns.deliveries") }}</h3>
              </header>
              <div class="content-body table-container">
                <table class="table">
                  <thead>
                    <tr>
                      <th>{{ $t("global.stats") }}</th>
                      <th>{{ $t("campaigns.displayName") }}</th>
                      <th>{{ $t("global.location") }}</th>
                      <th>{{ $t("campaigns.delivered") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="ad in ads" v-bind:key="ad.uuid">
                      <td width="20%">
                        <span v-if="ad.status == 'created'">{{ $t("devices.adRequest") }}</span>
                        <span v-if="ad.status == 'delivered'">{{ $t("devices.adServed") }}</span>
                      </td>
                      <td width="30%">
                        <span v-if="ad.device" style="display: inline-block; vertical-align: middle; margin-right: 5px">{{ ad.device.name }}</span>
                      </td>
                      <td width="20%">
                        <span v-if="ad.device">
                          <a :href="'http://www.google.com/maps/place/' + ad.device.lat + ',' + ad.device.lng" target="_blank" style="color: #444">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              style="width: 14px; height: 14px; color: #444; display: inline-block; vertical-align: middle"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                              />
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                            </svg>
                            <span style="display: inline-block; vertical-align: middle; cursor: pointer; margin-left: 5px">{{ $t("global.click") }}</span>
                          </a>
                        </span>
                      </td>
                      <td width="30%">
                        {{ ad.updated_at_formatted }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <p style="margin-bottom: 0; text-align: right">
              <input type="submit" :value="$t('global.saveNow')" class="button button-ci button-round" />
            </p>
          </form>
          <br />
          <br />
          <div class="content-wrap">
            <header class="content-header cf"><h3>Geografische Eingrenzung</h3></header>
            <div class="content-body">
              <MapView
                :initialZoom="9"
                :showCircle="true"
                :getPosition="false"
                :campaignMarker="true"
                :getCampaign="true"
                :clickRadius="false"
                :editRadius="false"
                :listView="true"
                style="width: 100%; height: 650px"
                ref="finalMap"
              ></MapView>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>

<script>
import axios from "axios";
import AdminNav from "@/components/admin_nav";
import MapView from "@/components/MapView";

export default {
  name: "dashboard",
  components: {
    AdminNav,
    MapView,
  },
  data() {
    return {
      search_phrase: "",
      campaign: {},
      industries: [],
      industry: "",
      subIndustries: [],
      subIndustry: "",
      ads: [],
    };
  },
  methods: {
    get_data() {
      axios
        .get(process.env.VUE_APP_BASE_API + "/admin/campaigns/" + this.$route.params.uuid, { headers: { Authorization: this.$store.getters.getToken } })
        .then((response) => {
          this.campaign = response.data.campaign;
          console.log(this.campaign);
        });

      this.get_industries(1);
    },
     async get_ads() {
      axios
        .get(process.env.VUE_APP_BASE_API + "/admin/campaigns/" + this.$route.params.uuid + "/ads", {
          headers: { Authorization: this.$store.getters.getToken },
        })
        .then((response) => {
          this.ads = response.data.ads;
        });
    },
    get_industries(page) {
      axios
        .get(process.env.VUE_APP_BASE_API + "/industries?page=" + page, {
          headers: { Authorization: this.$store.getters.getToken },
        })
        .then((response) => {
          this.industries = response.data.industries;

          this.subIndustry = this.campaign.industry_id;
          // get parent industry id
          this.industry = this.industries.flatMap((f) => f.industries).find((x) => x.id === this.subIndustry).industry_id;
          // get current subindustries based on parent id
          this.subIndustries = this.industries.find((x) => x.id === this.industry).industries;

          if (response.data.meta.pagination.links.next) {
            this.get_industries(response.data.meta.pagination.links.next.split("=")[1]);
          }
        });
    },
    update_campaign: function (e) {
      e.preventDefault();
      this.campaign.industry_id = this.subIndustry;

      axios
        .patch(process.env.VUE_APP_BASE_API + "/admin/campaigns/" + this.$route.params.uuid, this.campaign, {
          headers: { Authorization: this.$store.getters.getToken },
        })
        .then((response) => {
          console.log(response);
          this.$notify({
            group: "notification",
            type: "success",
            text: "Erfolgreich gespeichert.",
          });
          this.get_data();
        })
        .catch((error) => {
          error.response.data.errors.forEach((value) => {
            this.$notify({
              group: "notification",
              type: "error",
              text: value,
            });
          });
        });
    },
    onChange: function () {
      this.subIndustries = this.industries.find((x) => x.id === this.industry).industries;
    },
  },
  mounted() {
    this.get_data();
    this.get_ads();
  },
};
</script>
